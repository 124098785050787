import { useFirestoreGeneralOrderBy, useFirestoreGeneral } from '../../../firebase/useFirestore';
import MixedBarChart from '../../../components/Visualisations/personas/MixedBarChart';
import { useEffect, useState, useContext } from 'react';
import CalendarIcon from '../../../images/icons/calendar-icon.png';
import { db, timestamp } from '../../../firebase/config';
import { client } from "../../../hooks/Client";
import uuid from 'react-uuid';
import Tooltip from '../../../components/common/Tooltip';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import HistoryOutlinedIcon from '@mui/icons-material/HistoryOutlined';
import TableChartOutlinedIcon from '@mui/icons-material/TableChartOutlined';
import BarChartOutlinedIcon from '@mui/icons-material/BarChartOutlined';
import { Auth } from '../../../StateManagment/Auth';
import AssessmentOutlinedIcon from '@mui/icons-material/AssessmentOutlined';
import { useHistory } from "react-router-dom";
import PresenceTable from './PresenceTable';
import LocalPrintshopOutlinedIcon from '@mui/icons-material/LocalPrintshopOutlined';
import DeleteModal from "../../../components/common/DeleteModal";

const Presence = ({item, courseStartDate, courseEndDate}) => {
    // Context
    const [auth] = useContext(Auth);

    // State
    const [tempStartDate, setTempStartDate] = useState(courseStartDate);
    const [tempEndDate, setTempEndDate] = useState(courseEndDate);
    const [presenceData, setPresenceData] = useState([]);
    const [loading, setLoading] = useState(true); // Loading state for data
    const [totalPresent, setTotalPresent] = useState(0);
    const [totalSheduledPresence, setTotalSheduledPresence] = useState(0);
    const [startDate, setStartDate] = useState(courseStartDate);
    const [endDate, setEndDate] = useState(courseEndDate);
    const [graphType, setGraphType] = useState('bar');
    const [deleteModal, setDeleteModal] = useState(false)
    const [deleteDocid, setDeleteDocid] = useState('123')
    const [deleteName, setDeleteName] = useState('')

    // Hooks
    const options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' };
    const history = useHistory()
    const dayNames = ["zondag", "maandag", "dinsdag", "woensdag", "donderdag", "vrijdag", "zaterdag"];

    // Firestore
    const presenceReports = useFirestoreGeneralOrderBy(
        'PersonaPresenceReports',
        'PersonaID',
        item.ID ? item.ID : '',
        'Position',
        'asc',
      );
    const schedule = useFirestoreGeneral('PersonaShedule', 'PersonaID', item.ID ? item.ID : '');

    // Delete modal 
    const deleteModalHandler = (e) => {
      const docid = e.target.dataset.docid
      const deleteName = e.target.dataset.deletename

      setDeleteDocid(docid)
      setDeleteName(deleteName)
      setDeleteModal(!deleteModal)
    }

    // Set the start and end date of the presence reports
    useEffect(() => {
        setStartDate(courseStartDate);
        setEndDate(courseEndDate);
        setTempEndDate(courseEndDate);
        setTempStartDate(courseStartDate);
    }, [courseStartDate, courseEndDate]);

      // Helper function to get the start time from the schedule
  const getStartTimeFromSchedule = (weekday) => {
    console.log(weekday)
    const day = schedule && schedule.filter(item => item.Day === weekday);

    console.log(day)
    return day[0]?.StartTime;
  };

  // Helper function to get the end time from the schedule
  const getEndTimeFromSchedule = (weekday) => {
    const day = schedule && schedule.filter(item => item.Day === weekday);
    return day[0]?.EndTime;
  };

  // Helper function to format a date as 'YYYY-MM-DD' for easy comparison
  const formatDate = (date) => {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    return `${year}-${month}-${day}`;
  };

  const createHistoricalPresenceReports = () => {
    const scheduleArray = [];
    const today = new Date();
    const currentWeekday = today.getDay();
    const dayNames = ["sunday", "monday", "tuesday", "wednesday", "thursday", "friday", "saturday"];

    schedule && schedule.map(item => {
      const weekday = item.Day;
      const dayNumber = dayNames.indexOf(weekday);
      scheduleArray.push(dayNumber);
    });

    let lastWeekday = scheduleArray.filter(day => day < currentWeekday).pop();
    if (lastWeekday === undefined) {
      lastWeekday = scheduleArray[scheduleArray.length - 1];
    }

    const lastWeekdayDate = new Date(today.getTime());
    lastWeekdayDate.setDate(today.getDate() - (currentWeekday - lastWeekday));

    const normalizeDate = (date) => {
      const newDate = new Date(date);
      newDate.setHours(0, 0, 0, 0);
      return newDate;
    };

    const normalizedStartDate = normalizeDate(startDate);
    const normalizedLastWeekdayDate = normalizeDate(lastWeekdayDate);

    let currentDate = new Date(normalizedStartDate.getTime());

    // Initialize the position counter
    let positionCounter = presenceReports.length;

    while (normalizeDate(currentDate) <= normalizedLastWeekdayDate) {
      const currentWeekday = dayNames[currentDate.getDay()];
      const formattedCurrentDate = formatDate(currentDate);

      // Check if the report for the specific date already exists
      const reportExists = presenceReports.some(
        (report) => formatDate(parseCustomDate(report.Date)) === formattedCurrentDate
      );

      // Create a new report if the current date is in the schedule and a report doesn't already exist
      if (scheduleArray.includes(currentDate.getDay()) && !reportExists) {
        positionCounter++;  // Increment position counter for each report

        db.collection('PersonaPresenceReports').add({
          Date: currentDate.toLocaleDateString('nl-NL', options),
          Type: 'correct',
          Start: getStartTimeFromSchedule(currentWeekday),
          End: getEndTimeFromSchedule(currentWeekday),
          CorrectedStart: '',
          CorrectedEnd: '',
          Absent: '',
          Reason: '',
          PresenceID: '',
          PersonaID: item.ID,
          CompagnyID: client,
          Timestamp: timestamp,
          Author: auth.ID,
          ID: uuid(),
          Position: positionCounter 
        });
        console.log(`Presence report created for: ${currentWeekday} (${currentDate.toDateString()})`);
      }
      currentDate.setDate(currentDate.getDate() + 1);
    }
  };

  // Set default start and end date
  useEffect(() => {
    setStartDate(courseStartDate);
    setEndDate(courseEndDate);
  }, [courseStartDate, courseEndDate]);

  // Helper function to format the date to a Dutch string
  const formatDateToDutchString = (date) => {
    const daysOfWeek = ['zondag', 'maandag', 'dinsdag', 'woensdag', 'donderdag', 'vrijdag', 'zaterdag'];
    const monthsOfYear = ['januari', 'februari', 'maart', 'april', 'mei', 'juni', 'juli', 'augustus', 'september', 'oktober', 'november', 'december'];

    const dayName = daysOfWeek[date.getDay()];
    const day = date.getDate();
    const monthName = monthsOfYear[date.getMonth()];
    const year = date.getFullYear();

    return `${dayName} ${day} ${monthName} ${year}`;
  };

  // Helper function to normalize the date
  const normalizeDate = (reportDate) => {
    if (typeof reportDate === 'string') {
      return reportDate;
    }
    if (reportDate.seconds !== undefined && reportDate.nanoseconds !== undefined) {
      const milliseconds = reportDate.seconds * 1000 + reportDate.nanoseconds / 1000000;
      const dateObject = new Date(milliseconds);
      return formatDateToDutchString(dateObject);
    }
  };

  // Helper function to get the minutes between two times
  const getMinutesBetweenTimes = (startTime, endTime, correctedStartTime, correctedEndTime, type) => {
    // If the report type is 'absent', return 0
    if(type === 'absent') {
      return 0;
    }

    // If the corrected start and end times are provided, use those instead of the original times
    const start = correctedStartTime !== "" ? new Date(`1970-01-01T${correctedStartTime}:00`) : new Date(`1970-01-01T${startTime}:00`)
    const end = correctedEndTime !== "" ? new Date(`1970-01-01T${correctedEndTime}:00`) : new Date(`1970-01-01T${endTime}:00`)
    const diff = end - start;
    return diff / 1000 / 60 / 60;
  };

  // Helper function to get the simplified date
  const getSimplifiedDate = (date) => {
    const dateObject = new Date(date);
    return dateObject.getTime();
  };

  // Helper function to parse a custom date string like "maandag 22 juli 2024" into a JavaScript Date object
  const parseCustomDate = (customDateString) => {
    const monthsOfYear = {
      januari: 0,
      februari: 1,
      maart: 2,
      april: 3,
      mei: 4,
      juni: 5,
      juli: 6,
      augustus: 7,
      september: 8,
      oktober: 9,
      november: 10,
      december: 11
    };

    const parts = customDateString ?  customDateString && customDateString.split(' ') : ''; 
    const day = parseInt(parts[1]);
    const month = monthsOfYear[parts[2]];
    const year = parseInt(parts[3]);

    return new Date(year, month, day);
  };

  // Helper function to filter reports by the start and end dates
  const filterReportsByDate = () => {
    if (!presenceReports || presenceReports.length === 0) {
      return [];
    }

    const filteredReports = presenceReports.filter(report => {

      if(report.Date === undefined) {
        return false;
      }

    // Convert the custom date string to a Date object if it's not already
    let reportDate = report.Date;
    if(typeof report.Date === 'string') {
      reportDate = parseCustomDate(report.Date);  
    }
      const start = new Date(startDate);
      const end = new Date(endDate);

      // Filter the report if it falls within the selected date range
      return reportDate >= start && reportDate <= end;
    });

    return filteredReports;
  };

  // Helper function to get the sheduled presence for a specific date
  const getSheduledPresence = (startTime, endTime) => {

    const start = new Date(`1970-01-01T${startTime}:00`)
    const end =  new Date(`1970-01-01T${endTime}:00`)
    const diff = end - start;
    return diff / 1000 / 60 / 60;
    
  };

  // Main function to create the presence data for the selected date range to be shown in the table or graph
  const createPresenceData = () => {
    const presenceData = [];
    let addedTotalHoursOfPresence = 0;
    let addedTotalHoursOfSheduledPresence = 0;

    const filteredReports = filterReportsByDate();  // Get the filtered reports based on date range

    filteredReports && filteredReports.map(report => {
      const startTime = report.Start;
      const endTime = report.End;
      const correctedStartTime = report.CorrectedStart;
      const correctedEndTime = report.CorrectedEnd;
      const type = report.Type;

      const totalHoursOfSheduledPresence = getSheduledPresence(startTime, endTime);
      const totalHoursOfPresence = getMinutesBetweenTimes(startTime, endTime, correctedStartTime, correctedEndTime, type);

      if (!isNaN(totalHoursOfPresence)) {
        addedTotalHoursOfPresence += totalHoursOfPresence;
      }

      addedTotalHoursOfSheduledPresence += totalHoursOfSheduledPresence;

      const simplifiedDate = getSimplifiedDate(report.Date);

      // The 'Geroosterd and Aanwezigheid' fields are displayed in the graph. The absent and present fields are used for the calculations.
      presenceData.push({
        date: normalizeDate(report.Date),
        type: report.Type,
        absent: report.Absent,
        reason: report.Reason,
        Aanwezigheid: report.Type === 'absent' ? 0 : getMinutesBetweenTimes(startTime, endTime, correctedStartTime, correctedEndTime),
        Geroosterd: getSheduledPresence(startTime, endTime) - (getMinutesBetweenTimes(startTime, endTime, correctedStartTime, correctedEndTime)),
        present: report.Type === 'absent' ? 0 : getMinutesBetweenTimes(startTime, endTime, correctedStartTime, correctedEndTime),
        schedule: getSheduledPresence(startTime, endTime),
        id: report.ID,
        simplifiedDate: simplifiedDate,
        total:(report.Type === 'absent' ? 0 : getMinutesBetweenTimes(startTime, endTime, correctedStartTime, correctedEndTime)) - getSheduledPresence(startTime, endTime),
        Position: report.Position,
        docid: report.docid
      });
    });

    const orderedData = presenceData.sort((a, b) => a.simplifiedDate - b.simplifiedDate);
    setPresenceData(orderedData);
    setLoading(false);
    setTotalPresent(addedTotalHoursOfPresence);
    setTotalSheduledPresence(addedTotalHoursOfSheduledPresence);
  };

  useEffect(() => {
    if (presenceReports && presenceReports.length > 0) {
      createPresenceData();
    } else {
      setLoading(false);
    }
  }, [presenceReports, startDate, endDate]);

  const deleteAllReports = () => {
    presenceReports && presenceReports.map(report => {
      db.collection('PersonaPresenceReports').doc(report.docid).delete();
    });
  }

   // Filter presence data based on date range
   const filterPresenceDates = () => {
    setLoading(true);
    setStartDate(tempStartDate);
    setEndDate(tempEndDate);
    setLoading(false);
  };

  // Calculate the total hours paid
  const totalSheduledPaid = () => {
    const hoursPerWeek = item.HoursPerWeek

    // Number of weeks between the start and end date
    const startDateObject = new Date(startDate);
    const endDateObject = new Date(endDate);
    const totalDays = (endDateObject - startDateObject) / (1000 * 60 * 60 * 24);
    const totalWeeks = totalDays / 7;

    const totalHours = hoursPerWeek * totalWeeks;

    return totalHours.toFixed(0);
  }

  const CustomTooltip = ({ active, payload, label }) => {
    if (active && payload && payload.length) {
      const tooltipType = (type) => {
        if (type === 'correct') {
          return 'Correct';
        } else if (type === 'absent') {
          return 'Afwezig';
        } else if (type === 'correction') {
          return 'Gecorrigeerd';
        }
      };

      return (
        <div className="custom-tooltip">
          <p className="label">{payload[0].payload.date ? payload[0].payload.date : 'Geen datum'}</p>
          <h2 className="label" id='presence-tooltip-type'>{tooltipType(payload[0].payload.type)}</h2>
          <p className="desc" style={{ display: payload[0].payload.type === 'absent' ? 'none' : 'block' }}>Geroosterd: {payload[0].payload.schedule}</p>
          <p className="desc" style={{ display: payload[0].payload.type === 'absent' ? 'none' : 'block' }}>Aanwezig: {payload[0].payload.present}</p>
          <p className="desc" style={{ display: payload[0].payload.reason === '' ? 'none' : 'block' }}>Reden: {payload[0].payload.reason}</p>
        </div>
      );
    }

    return null;
  };

  return (
    <div className='dashboard-container'>
        <h2>Aanwezigheid</h2>
        <div id='date-range-selecttor-container'>
          <div className='select-date-container'>
            <input type="date" defaultValue={courseStartDate} onChange={(e) => setTempStartDate(e.target.value)} />
          </div>
          <div className='select-date-container'>
            <input type="date" defaultValue={courseEndDate} onChange={(e) => setTempEndDate(e.target.value)} />
          </div>
          <button className='button-simple' onClick={filterPresenceDates}>Filter</button>
        </div>
        <div className='list-top-row-container'>
          <Tooltip content={'Bekijk in grafiek'} top='35px' width='30px'>
              <BarChartOutlinedIcon onClick={() => setGraphType('bar')} />
          </Tooltip>
          <Tooltip content={'bekijk in tabel'} top='35px' width='30px'>
              <TableChartOutlinedIcon onClick={() => setGraphType('table')}/>
          </Tooltip>
      </div>
        <div id='presence-data-outer-container'>
          <div className='presence-data-container'>
            <p><b>Totaal vergoed</b></p>
            <p>{totalSheduledPaid()} uur</p>
          </div>
          <div className='presence-data-container'>
            <p><b>Totaal geroosterd</b></p>
            <p>{totalSheduledPresence} uur</p>
          </div>
          <div className='presence-data-container'>
            <p><b>Totaal aanwezig</b></p>
            <p>{totalPresent} uur</p>
          </div>
          <div className='presence-data-container'>
            <p><b>Totaal afwezig</b></p>
            <p>{totalPresent - totalSheduledPresence} uur</p>
          </div>
        </div>
       
        {graphType === 'table' && 
       <PresenceTable 
       presenceData={presenceData} 
       length={presenceReports.length} 
       getStartTimeFromSchedule={getStartTimeFromSchedule} 
       getEndTimeFromSchedule={getEndTimeFromSchedule} 
       auth={auth}
       personaId={item.ID}
      />}

        {graphType === 'bar' && 
        <MixedBarChart 
        data={presenceData} 
        customTooltip={<CustomTooltip />} 
        />}

        <div id='persona-presence-options-container'>
          <Tooltip content='Print aanwezigheidsrapport' width={'30px'} top={'-80px'}>
            <LocalPrintshopOutlinedIcon onClick={() => history.push(`/${client}/printpresencereport/${item.ID}/${startDate}/${endDate}`)}/>
          </Tooltip>
          <Tooltip content='Creëer ontbrekende rapporten' width={'30px'} top={'-80px'}>
            <HistoryOutlinedIcon onClick={createHistoricalPresenceReports} />
          </Tooltip>
          <Tooltip content='Verwijder alle rapporten' width={'30px'} top={'-80px'}>
            <DeleteOutlineOutlinedIcon onClick={deleteModalHandler} />
          </Tooltip>
        </div>
        <DeleteModal deleteModal={deleteModal} setDeleteModal={setDeleteModal} deleteItem={deleteAllReports} deleteName={deleteName} />
      </div>
      
  )
}

export default Presence