import CalendarTodayOutlinedIcon from '@mui/icons-material/CalendarTodayOutlined';
import { useEffect, useState, useContext } from 'react';
import { useFirestoreGeneral, useFirestore } from '../../../firebase/useFirestore';
import PersonaNameMeta from '../../../components/personas/PersonaNameMeta';
import CheckOutlinedIcon from '@mui/icons-material/CheckOutlined';
import XIcon from '../../../images/icons/x-icon.png';
import { Auth } from '../../../StateManagment/Auth'
import { timestamp, db } from '../../../firebase/config';
import { v4 as uuid } from 'uuid';
import { client } from "../../../hooks/Client"
import ErasorIcon from '../../../images/icons/erasor-icon.png';

const PresenceToday = () => {
    // Context
    const [auth] = useContext(Auth);

    // State
    const [absent, setAbsent] = useState(false);
    const [absentReason, setAbsentReason] = useState('');
    const [makeCorrection, setMakeCorrection] = useState({ id: '', makeCorrection: false });
    const [correctStartTime, setCorrectStartTime] = useState('');
    const [correctEndTime, setCorrectEndTime] = useState('');
    const [correctionReason, setCorrectionReason] = useState('');
    const [displayCorrection, setDisplayCorrection] = useState(false);

    // Firestore
    const shedule = useFirestore('PersonaShedule');
    const presenceReports = useFirestore('PersonaPresenceReports');

    // Hooks
    const today = new Date();
    const dayName = new Intl.DateTimeFormat('en-EN', { weekday: 'long' }).format(today);
    const dutchDayName = new Intl.DateTimeFormat('nl-NL', { weekday: 'long' }).format(today);
    const capitalizedDutchDayName = dutchDayName.charAt(0).toUpperCase() + dutchDayName.slice(1);
    const longName = new Intl.DateTimeFormat('nl-NL', { day: 'numeric', month: 'long', year: 'numeric' }).format(today);
    const hours = today.getHours();
    const minutes = today.getMinutes();

    // Helper function to format the date to a Dutch string
   const formatDateToDutchString = (date) => {
    const daysOfWeek = ['zondag', 'maandag', 'dinsdag', 'woensdag', 'donderdag', 'vrijdag', 'zaterdag'];
    const monthsOfYear = ['januari', 'februari', 'maart', 'april', 'mei', 'juni', 'juli', 'augustus', 'september', 'oktober', 'november', 'december'];

    const dayName = daysOfWeek[date.getDay()];
    const day = date.getDate();
    const monthName = monthsOfYear[date.getMonth()];
    const year = date.getFullYear();

    return `${dayName} ${day} ${monthName} ${year}`;
  };

    // Filter presence for today
    const presenceToday = () => {
        const array = [];

        shedule && shedule.forEach(item => {

        if (item.Day.toLowerCase() === dayName.toLowerCase()) {
            const object = {
            name: item.PersonaID,
            start: item.StartTime,
            end: item.EndTime,
            };
            array.push(object);
        }
        });

        return array;
    };

    // Show checked
    const showChecked = (presence) => {
        return presenceReports && presenceReports?.some(report => {
        const reportDate = new Date(report.Date); // Ensure report.Date is converted to a Date object
        return report.PersonaID === presence.name && 
                reportDate.toDateString() === new Date().toDateString();
        }) || false;
    };

    // Helper function to check for main activity
  const checkForMainActivity = async (personaId) => {

    const mainActivity = await db.collection('PersonaActivities')
      .where('PersonaID', '==', personaId)
      .where('Type', '==', 'main')
      .get();

    if (mainActivity.empty) {
      return 
    } else {
        mainActivity.forEach( async doc => {

          const eventId = uuid();

          await db.collection('Events').add({
            Title: '',
            Date: timestamp,
            Outputs: [doc.data().OutputId],
            Personas: [personaId],
            ActivityID: doc.data().ActivityID,
            ID: eventId,
            Compagny: client,
            CompagnyID: client,
        });

        await db.collection('Results')
          .add({
            Compagny: client,
            CompagnyID: client,
            ID: uuid(),
            Result: Number(1),
            Timestamp: timestamp,
            OutputID: doc.data().OutputId,
            ActivityID: doc.data().ActivityID,
            EventID: eventId,
            Personas: personaId,
          })
      });

    }
  };

    // Save correction
  const saveCorrection = (e) => {
    const personaId = e.target.dataset.personaid;
    const start = e.target.dataset.start;
    const end = e.target.dataset.end;

    const position = presenceReports?.filter(report => report.PersonaID === personaId).length + 1;

    db.collection('PersonaPresenceReports')
      .add({
        PersonaID: personaId,
        Start: start,
        End: end,
        CorrectedStart: correctStartTime,
        CorrectedEnd: correctEndTime,
        Date: formatDateToDutchString(new Date()),
        CompagnyID: client,
        Timestamp: timestamp,
        Reason: correctionReason,
        ID: uuid(),
        Type: 'correction',
        Author: auth.ID,
        Position: position
      })
      .then(() => {
        checkForMainActivity(personaId);
        setDisplayCorrection(false);
      });
  };

  // Save absent
  const saveAbsent = (e) => {
    const personaId = e.target.dataset.personaid;

    db.collection('PersonaPresenceReports')
      .add({
        PersonaID: personaId,
        Date: formatDateToDutchString(new Date()),
        CompagnyID: client,
        Timestamp: timestamp,
        ID: uuid(),
        Type: 'absent',
        Reason: absentReason,
        Author: auth.ID,
      })
      .then(() => {
        setAbsent(false);
        setMakeCorrection(false);
      });
  
  }

  return (
    <div>
          <div className="title-edit-container home-section-title-container">
            <CalendarTodayOutlinedIcon className='icon' />
            <h2>Vandaag aanwezig</h2>
          </div>
          <div id='persona-dashboard-presence-today-container'>
            <p id='persona-dashboard-day-name'>{capitalizedDutchDayName}</p>
            <p>{longName}</p>
            <div id='persona-dashboard-presence-today-time-container'>
              {/* <p>{hours}:</p> */}
              {/* <p>{minutes}</p> */}
            </div>
          </div>

          <div>
            {presenceToday().map((presence, index) => (
              <div key={index} id='dashboard-presence-container'>
                <div id='dashboard-presence-name-container'>
                  <PersonaNameMeta personaID={presence.name} />
                </div>

                <div id='dashboard-presence-times-container'>
                  <p>{presence.start}</p>
                  <p>-</p>
                  <p>{presence.end}</p>
                </div>

                <div id='check-precense-container'>
                  {showChecked(presence) ?
                    <div id='presence-is-checked-notice-container'>
                      <CheckOutlinedIcon />
                      <p>Gecontroleerd</p>
                    </div>
                    :
                    <div>
                      <div id='register-presence-container'>
                      <div 
                        className='register-precense-options-container' 
                        onClick={() => setMakeCorrection(prevState => ({ 
                          id: presence.name, 
                          makeCorrection: !prevState.makeCorrection 
                        }))}
                      >
                          <img src={ErasorIcon} alt="erasor"  />
                          <p>Correctie</p>
                        </div>
                        <div className='register-precense-options-container'>
                          <img src={XIcon} alt="x" onClick={() => setAbsent(!absent)} />
                          <p onClick={() => setAbsent(!absent)}>Afwezig</p>
                        </div>
                      </div>
                      <div id='register-presence-container' style={{ display: makeCorrection.makeCorrection && makeCorrection.id === presence.name ? 'flex' : 'none',   }}>
                        <p>Correctie</p>
                        <input type="time" onChange={(e) => setCorrectStartTime(e.target.value)} />
                        <p>-</p>
                        <input type="time" onChange={(e) => setCorrectEndTime(e.target.value)} />
                        <input type="text" placeholder='Reden correctie' onChange={(e) => setCorrectionReason(e.target.value) } />
                        <button className='button-simple' data-start={presence.start} data-end={presence.end} data-personaid={presence.name} onClick={saveCorrection}>Opslaan</button>
                      </div>
                      <div style={{ display: absent ? 'flex' : 'none' }}>
                        <p>Reden afwezigheid</p>
                        <select name="" id="" onChange={(e) => setAbsentReason(e.target.options[e.target.selectedIndex].value)}>
                          <option value="">-- Selecteer reden afwezigheid --</option>
                          <option value="ziek">Ziek</option>
                          <option value="verlof">Verlof</option>
                          <option value="overig">Overig</option>
                        </select>
                        <button className='button-simple' data-personaid={presence.name} onClick={saveAbsent}>Opslaan</button>
                      </div>
                    </div>
                  }
                </div>
              </div>
            ))}
          </div>
        </div>
  )
}

export default PresenceToday