import { useFirestore, useFirestoreOrderBy } from "../../firebase/useFirestore";
import ScrollToTop from "../../hooks/ScrollToTop";
import useSettings from "../../hooks/Settings";
import { useHistory } from "react-router-dom";
import { client } from "../../hooks/Client";
import AssessmentOutlinedIcon from "@mui/icons-material/AssessmentOutlined";
import Tooltip from "../../components/common/Tooltip";
import DeleteModal from "../../components/common/DeleteModal";
import { SavedIcon } from "../../StateManagment/SavedIcon";
import { Auth } from "../../StateManagment/Auth";
import { ImpactAI } from "../../StateManagment/ImpactAI";
import uuid from "react-uuid";
import { db, timestamp } from "../../firebase/config";
import { useState, useEffect, useContext } from "react";
import { LiveReportTitleSystemMessage, LiveReportTitleToolCallContent, LiveReportTitleSaveMessage } from "../../hooks/impactAI/Prompts"
import DraggableTableItems from "../../components/common/DraggableTableItems";
import deleteIcon from "../../images/icons/delete-icon.png";
import Breadcrumb from "../../components/common/Breadcrumb";
import printIcon from "../../images/icons/print-icon.png"
import CopyLink from "../../components/common/CopyLink";
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import AddItemTableRow from "../../components/common/AddItemTableRow";

const LiveReports = () => {
    // Context
    const [saved, setSaved] = useContext(SavedIcon)
    const [auth] = useContext(Auth)
    const {
        type: [type, setType],
        systemMessage: [systemMessage, setSystemMessage],
        toolCallContent: [toolCallContent, setToolCallContent],
        collection: [collection, setCollection],
        docid: [docid, setDocid],
        field: [field, setField],
        saveMessage: [saveMessage, setSaveMessage],
        startMessage: [startMessage, setStartMessage],
        parentId: [parentId, setParentId],
        exampleMessages: [exampleMessages, setExampleMessages],
        hightlighted: [hightlighted, setHightlighted],
        hightlightText: [hightlightText, setHightlightText],
        chatOpen: [chatOpen, setChatOpen]
    } = useContext(ImpactAI);

    // State
    const [hoverIndex, setHoverIndex] = useState(null)
    const [deleteModal, setDeleteModal] = useState(false)
    const [deleteDocid, setDeleteDocid] = useState('123')
    const [deleteName, setDeleteName] = useState('')
    const [defaultStartDate, setDefaultStartDate] = useState(null)
    const [placeholderIndex, setPlaceholderIndex] = useState(null);

    // Hooks
    ScrollToTop()
    const secundairyColor = useSettings().SecundairyColor
    const subscriptionType = useSettings().subscriptionType
    const history = useHistory()

    // Firestore
    const reports = useFirestoreOrderBy('LiveReports', 'Position', 'asc')  
    const compagny = useFirestore("CompagnyMeta")

    //ImpactAI
    const pageSystemMessage = LiveReportTitleSystemMessage()
    const pageToolCallContent = LiveReportTitleToolCallContent()
    const pageSaveMessage = LiveReportTitleSaveMessage()
    const pageStartMessage = `Welkom, ${auth?.ForName}. In deze stap gaan we de titels voor live rapportages creëeren.
    Mag ik een voorstel doen?`
    const pageCollection = 'LiveReports'
    const pageField = 'Title'
    const pageParentId = ''
    const pageType = 'liveReportTitle'

    useEffect(() => {
        setType(pageType)
        setSystemMessage(pageSystemMessage)
        setStartMessage(pageStartMessage)
        setToolCallContent(pageToolCallContent)
        setSaveMessage(pageSaveMessage)
        setCollection(pageCollection)
        setField(pageField)
        setParentId(pageParentId)
        setExampleMessages([])
    }, [auth])

    // Hightlight impactAI
    const impactaiHandler = () => {
        setChatOpen(true)
        setHightlighted(!hightlighted)
        setHightlightText('Ja, ga je gang!')
    }

    // Formatted date
    const formatDate = (date) => {
        if (!date) return null; // Handle null or undefined dates
        const isoDate = date.toISOString().split('T')[0]; // Extract yyyy-mm-dd
        const [year, month, day] = isoDate.split('-'); // Destructure into parts
        return `${day}-${month}-${year}`; // Return formatted date
      }

    // Set the default start date as the date the compagny profile was created
    const findDefaultStartDate = () => {

        compagny && compagny.forEach(item => {
            setDefaultStartDate(item.Timestamp)
        })
    }

    useEffect(() => {
        findDefaultStartDate()
    },[compagny])

    const addReport = () => {
        db.collection('LiveReports')
        .add({
            Title: 'Nieuwe live rapportage',
            Timestamp: timestamp,
            Compagny: client,
            CompagnyID: client,
            Position: reports.length + 1,
            ID: uuid(),
            Banner: 'https://firebasestorage.googleapis.com/v0/b/deccos-app.appspot.com/o/DALL%C2%B7E%202024-01-01%2012.46.53%20-%20An%20animated%20image%20of%20a%20group%20of%20people%20having%20fun%20in%20the%20flat%20Dutch%20countryside%2C%20with%20a%20style%20reflecting%20the%20rich%20colors%20and%20detailed%20comic-like%20aesth.png?alt=media&token=8dbd8a13-1875-4568-984b-abfb6c5c9c37',
            TargetgroupTitle: 'Doelgroep(en)',
            ActivityTitle: 'Activiteit(en)',
            KPITitle: 'Effect(en)',
            ResearchTitle: 'Onderzoek(en)',
            Start: null,
            End: null,
            Position: reports.length + 1
        })
    }

     // Delete modal 
     const deleteModalHandler = (e) => {
        const docid = e.target.dataset.docid
        const deleteName = e.target.dataset.deletename

        setDeleteDocid(docid)
        setDeleteName(deleteName)

        setDeleteModal(!deleteModal)
    }


    const deleteReport = (e) => {

        db.collection('LiveReports')
        .doc(deleteDocid)
        .delete()
    }

    const titleHandler = (e) => {
        const docid = e.target.dataset.docid
        const title = e.target.value

        db.collection('LiveReports')
        .doc(docid)
        .update({
            Title: title
        })
        .then(() =>{
            setSaved(true)
        })
    }

     // Reset hightlights
     useEffect(() => {
        setHightlighted(false)
        setHightlightText('')
    },[])

    return (
        <div className="main">
        <div className="main-container" style={{display: subscriptionType === 'basis' ? 'none' : 'flex'}}>
            <div className='page-header'>
                <AssessmentOutlinedIcon/>
                <h1>Live rapportages</h1>
            </div>
            <div className="table-container dashboard-container">
                <table>
                    <tr>
                        <th style={{backgroundColor: secundairyColor}}>TITEL</th>
                        <th style={{backgroundColor: secundairyColor}}>PERIODE</th>
                        <th style={{backgroundColor: secundairyColor}}>LINK</th>
                        <th style={{backgroundColor: secundairyColor}}>PRINT</th>
                        <th style={{backgroundColor: secundairyColor}}>AANPASSEN</th>
                        <th style={{backgroundColor: secundairyColor}}>VERWIJDER</th>
                    </tr>
                    {reports && reports.map((item, index) => (
                            <>
                            {placeholderIndex === index && (
                                <tr className="placeholder-row">
                                    <td style={{backgroundColor: secundairyColor}}></td>
                                </tr>
                            )}
                            <DraggableTableItems 
                            item={item}
                            input={
                                <Tooltip content={item.Title} width='100%' top='-60px'>
                                    <input type="text" defaultValue={item.Title} data-docid={item.docid} placeholder='Noteer hier de externe factor' onChange={titleHandler}/>
                                </Tooltip>
                            }
                            parentIndex={index}
                            collection={'LiveReports'}
                            itemArray={reports}
                            setHoverIndex={setHoverIndex}
                            hoverIndex={hoverIndex}
                            title={item.Title}
                            setPlaceholderIndex={setPlaceholderIndex}
                            >
                                <td>
                                    <div id='live-reports-overview-date-range-container'>
                                        <p>{item.Start ? formatDate(item.Start.toDate()) : 'Alles'}</p>
                                        <p>-</p>
                                        <p>{item.End ? formatDate(item.End.toDate()) : 'Alles'}</p>
                                    </div>
                                </td>
                                <td>
                                    <CopyLink link={`/${client}/livereportopen/${item.ID}/open`}/>
                                </td>
                                <td>
                                    <Tooltip content={'Print liverapport'} width='100%' top='-60px'>
                                        <img className='table-delete-icon' src={printIcon} alt="" onClick={() => window.open(`/${client}/printlivereport/${item.ID}`, '_blank')}/>
                                    </Tooltip>
                                </td>
                                <td>
                                    <Tooltip content={'Liverapport aanpassen'} width='100%' top='-60px'>
                                        <EditOutlinedIcon className='table-delete-icon' onClick={() => history.push(`/${client}/livereportbuilder/${item.ID}`)}/>
                                    </Tooltip>
                                </td>
                                <td>
                                    <Tooltip content={'Liverapport verwijderen'} width='100%' top='-60px'>
                                        <img className='table-delete-icon' data-docid={item.docid} data-deletename={item.Title} onClick={deleteModalHandler} src={deleteIcon} alt="" />
                                    </Tooltip>
                                </td>
                            </DraggableTableItems>
                        </>
                    ))}
                </table>
                <AddItemTableRow toolTipContent={'Live-rapportage toevoegen'} onClick={addReport}/>
            </div>
            <Breadcrumb
            section={'Communiceren'}
            previousPage={'Communiceren'}
            previousPageUrl={'communication'}
            nextPage={''}
            nextPageUrl={''}
            counter={1}
            totalSteps={1}
            />
        </div>
        <DeleteModal deleteModal={deleteModal} setDeleteModal={setDeleteModal} deleteItem={deleteReport} deleteName={deleteName} />
    </div>
  );
};

export default LiveReports;
