import { useContext, useEffect, useState } from 'react';
import { client } from '../../hooks/Client';
import { useHistory } from "react-router"
import { SavedIcon } from '../../StateManagment/SavedIcon'
import Location from '../../hooks/Location';
import Hostname from '../../hooks/Hostname';
import useSettings from '../../hooks/Settings';
import { db, auth } from '../../firebase/config';
import { Auth } from '../../StateManagment/Auth';
import { useFirestoreNoCompagnyGeneralTwoOrderBy, useFirestoreNoCompagny } from '../../firebase/useFirestore';
import OnboardingModal from '../common/OnboardingModal';
import { Onboarding } from '../../StateManagment/Onboarding';
import TopBarBreadCrumbs from '../common/TopBarBreadCrumb';
import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined';
import SwitchAccount from '../common/SwitchAccount';
import { ActiveMenu } from "../../StateManagment/ActiveMenuItem";

const Topbar = () => {
    // Context
    const [authO] = useContext(Auth)
    const [saved, setSaved] = useContext(SavedIcon)
    const { step, setStep } = useContext(Onboarding);
    const { active, setActive, activeSubItem, setActiveSubItem } = useContext(ActiveMenu);

    // State
    const [logo, setLogo] = useState('https://firebasestorage.googleapis.com/v0/b/deccos-app.appspot.com/o/Logos%2FLOGO-logo.png?alt=media&token=2785407c-d390-4ddd-a7c8-db38d1b6a0ba')
    const [display, setDisplay] = useState('block')
    const [displayTopbar, setDisplayTopbar] = useState('block')
    const [compagnyLogo, setCompagnyLogo] = useState(null)
    const [displayProfileMenu, setDisplayProfileMenu] = useState(false)
    const [superAdminsArray, setSuperAdminsArray] = useState([])

    // Hooks
    const history = useHistory()
    const urlTwo = Location()[2]
    const urlOne = Location()[1]
    const urlFour = Location()[4]
    const urlSix = Location()[6]
    const host = Hostname()
    const trailDays = useSettings().trailDays
    const subscription = useSettings().subscription
    const secondaryColor = useSettings().SecundairyColor

    // Firestore
    const superAdmins = useFirestoreNoCompagny('Users', 'SuperAdmin', true)
    const notifications = useFirestoreNoCompagnyGeneralTwoOrderBy('Notifications', 'Reciever', client, 'Read', false, 'Timestamp', 'desc');

    // Get compagny logo
    useEffect(() => {
       
        db.collection("CompagnyMeta")
        .where("CompagnyID", "==", urlOne)
        .get()
        .then((querySnapshot) => {
            querySnapshot.forEach((doc) => {
                setCompagnyLogo(doc.data().Logo)
            }
            )
        })
        .catch((error) => {
            console.log("Error getting documents: ", error);
        })
    }
    ,[urlOne])

    // Create array of superAdmins
    useEffect(() => {
        if (superAdmins) {
            const adminIDs = superAdmins.map(admin => admin.ID); // Extract all IDs
            setSuperAdminsArray(adminIDs); // Update the state once with all IDs
        }
    }, [superAdmins]);

    // Check if days since creation is more than 30
    const checkDaysSinceCreation = () => {
        if(subscription === 'trial' && trailDays !== 0){
            return 'flex'
        } else{
            return 'none'
        }
    }

    // Display logo and hide iconbar if client is not approved, not registered or not logged in
    const displayLogo = () => {

        if(client === '' || urlTwo === 'login' || urlOne === 'login' || urlOne === 'createaccount' || urlOne === 'impactplan' ){
            setLogo(host.Logo)
            setDisplay('none')
        } else if(urlTwo === 'Register' || urlTwo === 'NotApproved' || urlTwo === 'RegisterInvitation'){
            setDisplay('none')
            setLogo(compagnyLogo)
        } else{
            setLogo(compagnyLogo)
            setDisplay('block')
        }
    }

    const showTopbar = () => {
        if( urlFour === 'open' || urlSix === 'open'){
            setDisplayTopbar('none')
        } else{
            setDisplayTopbar('block')
        }
    }

    // Log out
    const logOut = () => {
        auth.signOut()
        .then(() => {
            history.push(`/home`)
            window.location.reload()
        }) 
    }

    useEffect(() => {
        displayLogo()
    },[ client, urlTwo, urlOne, compagnyLogo, host ])

    useEffect(() => {
        showTopbar()
    },[urlFour, urlSix])

    console.log(superAdminsArray.includes(authO?.ID))

    return (
        <>
        <header id="top-bar-container" style={{display: displayTopbar}}>
            <div className="top-bar">
                <TopBarBreadCrumbs />
                {/* <div style={{display: checkDaysSinceCreation()}} id='subscribtion-container'>
                    <p id='days-left-in-trial-text'>Proefabonnement eindigd over {trailDays} dagen</p>
                    <p>&nbsp;  - &nbsp; </p>
                    <ArrowUpwardOutlinedIcon style={{fill: secondaryColor}} onClick={() => history.push(`/${client}/subscription`)}/>
                    <p id='upgrade-cta' style={{color: secondaryColor}} onClick={() => history.push(`/${client}/subscription`)}>Upgrade</p>
                </div> */}
                <div id='topbar-icons-container'>
                    {/* <SaveAltOutlinedIcon style={{display: saved}} id='topbar-saved-icon'/> */}
                    
                    <div 
                        className="profile-photo"
                        onMouseEnter={() => setDisplayProfileMenu(true)}
                        onMouseLeave={() => setDisplayProfileMenu(false)}>
                        <div id='photo-container-topbar'>
                            <PersonOutlineOutlinedIcon id='topbar-user-icon'/>
                            {notifications && notifications.length > 0 &&
                                <div id='notification-badge'>
                                    <p>{notifications && notifications.length}</p>
                                </div>
                            }
                        </div>
                        <div 
                            id='profile-menu'
                            style={{display: displayProfileMenu ? 'flex' : 'none'}}
                            >
                            <div 
                            className='profile-menu-group-container' 
                            id='profile-menu-user-info-container'
                            onClick={() => {
                                history.push(`/${client}/profile`)
                                setActive('')
                                setActiveSubItem('')
                            }}
                            >
                                <img src={authO && authO.Photo} alt="" id='topbar-photo'/>
                                <div id='profile-menu-name-container'>  
                                    <p>{authO && authO.ForName} {authO && authO.SurName }</p>
                                    <p id='profile-menu-email'><i>{authO && authO.Email}</i></p>
                                </div>
                            </div>
                            <div 
                            className='profile-menu-group-container'
                            onClick={() => {
                                history.push(`/${client}/profile`)
                                setActive('')
                                setActiveSubItem('')
                            }}
                            >
                                <p>Mijn profiel</p>
                            </div>
                            <div className='profile-menu-group-container'>
                                <p   onClick={() => {
                                history.push(`/${client}/settings`)
                                setActive('')
                                setActiveSubItem('')
                                }}>Instellingen</p>
                                <p   onClick={() => {
                                history.push(`/${client}/Members`)
                                setActive('')
                                setActiveSubItem('')
                                }}>Team</p>
                                <p   onClick={() => {
                                history.push(`/${client}/Data`)
                                setActive('')
                                setActiveSubItem('')
                                }}>Data</p>
                            </div>
                            <div>
                                {superAdminsArray.includes(authO?.ID) && 
                                    <div 
                                    className='profile-menu-group-container'
                                    onClick={() => {
                                        history.push(`/${client}/adminfunds`)
                                        setActive('')
                                        setActiveSubItem('')
                                    }}
                                    >
                                        <p>Fondsen beheer</p>
                                    </div>
                                }
                            </div>
                            <div 
                            className='profile-menu-group-container'
                            onClick={() => {
                                history.push(`/${client}/notifications`)
                                setActive('')
                                setActiveSubItem('')
                            }}
                            >
                                <div id='profile-menu-notifications-container'>
                                    <p>Notificaties</p>
                                    {notifications && notifications.length > 0 &&
                                        <div id='notification-badge-profile-menu-container'>
                                            <p>{notifications && notifications.length}</p>
                                        </div>
                                    }
                                </div>
                            </div>
                            <div 
                            className='profile-menu-group-container'
                            onClick={() => {
                                history.push(`/${client}/helpdesk`)
                                setActive('')
                                setActiveSubItem('')
                            }}
                            >
                                <p>Helpdesk</p>
                            </div>
                            <div className='profile-menu-group-container'>
                                <SwitchAccount />
                            </div>
                            <div className='profile-menu-group-container'>
                                <p onClick={logOut}>Uitloggen</p>
                            </div>
                           
                        </div>
                    </div>
                </div>
            </div>
            {/* {step === 1 &&
             <OnboardingModal 
                selector='#topbar-photo'
                title={onboardingTitleProfile}
                text={onboardingTextProfile}
             />
            }
            {step === 2 &&
             <OnboardingModal 
                selector='#topbar-support-icon'
                title={onboardingTitleSupport}
                text={onboardingTextSupport}
             />
            } */}
        </header>
        </>
    )
}

export default Topbar
