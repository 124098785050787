import OutputResultsGraphResponsive from "../Visualisations/outputs/OutputResultsGraphResponsive"
import OpenQuestionSlider from "../liveReport/OpenQuestionSlider"
import MultipleData from "../graphData/MultipleData"
import ScaleData from "../graphData/ScaleData"
import ParagraphData from "../graphData/ParagraphData"
import MatrixData from "../graphData/MatrixData"

const DataType = ({ 
    type, 
    fieldId, 
    indicator, 
    startDate, 
    endDate, 
    researchId, 
    graphType, 
    personaId,
    paragrapgMomentVisibilityStyle
   }) => {

    // Determine the correct field value
    const field = indicator?.ID || fieldId;

     // Function to render chart type
     const renderData = () => {
        switch (type) {
          case 'multiple-one':
          case 'multiple-multiple':
            return <MultipleData researchId={researchId} field={field} startDate={startDate} endDate={endDate} graphType={graphType} />;
            
          case 'scale':
            return <ScaleData researchId={researchId} field={field} startDate={startDate} endDate={endDate} graphType={graphType} />;
            
          case 'paragraph':
            { if (paragrapgMomentVisibilityStyle === 'both'){
              return <> <ParagraphData researchId={researchId} field={field} startDate={startDate} endDate={endDate} graphType={graphType} />
                      <OpenQuestionSlider researchId={researchId} field={field} startDate={startDate} endDate={endDate} />
                    </>
              } else if(paragrapgMomentVisibilityStyle === 'graph'){
                return <ParagraphData researchId={researchId} field={field} startDate={startDate} endDate={endDate} graphType={graphType} />
              } else if(paragrapgMomentVisibilityStyle === 'text'){
                return <OpenQuestionSlider researchId={researchId} field={field} startDate={startDate} endDate={endDate} />
              } else {
                return <> <ParagraphData researchId={researchId} field={field} startDate={startDate} endDate={endDate} graphType={graphType} />
                        <OpenQuestionSlider researchId={researchId} field={field} startDate={startDate} endDate={endDate} />
                      </>
              }
            }
            
        case 'matrix-one':
        case 'matrix-multiple':
            return <MatrixData researchId={researchId} field={field} startDate={startDate} endDate={endDate} personaId={personaId} graphType={graphType} />; 
  
        case 'output':
            return <OutputResultsGraphResponsive output={field} color={indicator?.Color} startDate={startDate} endDate={endDate} />;
            
          default:
            return <p>Unknown type</p>;
        }
      };


    return <>
    {renderData()}
    </>;

}

export default DataType