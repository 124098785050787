import informationIcon from '../../images/icons/information-icon.png';
import Tooltip from "../common/Tooltip";
import { useEffect, useState } from "react";
import { db } from "../../firebase/config";
import QuestionMeta from "../dashboard/QuestionMeta";
import { useFirestoreGeneral } from "../../firebase/useFirestore";
import Modal from 'react-modal';
import Location from '../../hooks/Location';
import LineChartIcon from '@mui/icons-material/SsidChartOutlined';
import PieChartIcon from '@mui/icons-material/PieChartOutlineOutlined';
import BarChartIcon from '@mui/icons-material/BarChartOutlined';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import VisibilityOffOutlinedIcon from '@mui/icons-material/VisibilityOffOutlined';
import ColorLensOutlinedIcon from '@mui/icons-material/ColorLensOutlined';
import ChatOutlinedIcon from '@mui/icons-material/ChatOutlined';
import ColorPickerHousestyle from '../common/ColorPickerHousestyle';
import DataType from '../Visualisations/DataType';

const IndicatorData = ({ indicator, startDate, endDate, docid, graphType, personaId, paragrapgMomentVisibilityStyle, fieldType, liveReportHidden }) => {

    // State
    const [title, setTitle] = useState('');
    const [researchId, setResearchId] = useState('');
    const [numberOfMeasureMoments, setNumberOfMeasureMoments] = useState('');
    const [type, setType] = useState('');
    const [momentId, setMomentId] = useState('');
    const [showOptions, setShowOptions] = useState(false);
    const [modalOpen, setModalOpen] = useState(false);
    const [showResponsesVisibilityOptions, setShowResponsesVisibilityOptions] = useState(false)
      const [modalGraphTypeOpen, setModalGraphTypeOpen] = useState(false)

    // Hooks
    const currentPage = Location()[2];
    Modal.setAppElement('#root');

    const modalStyles = {
        content: {
            margin: '5%'
        },
    };

    // Firestore
    const research = useFirestoreGeneral('Research', 'QuestionnaireID', indicator.QuestionnaireID ? indicator.QuestionnaireID : '');
    const measureMoments = useFirestoreGeneral('MeasureMoments', 'ResearchID', researchId ? researchId : '');
    const paragraphCategories = useFirestoreGeneral('AnalysisCategories', 'FieldID', indicator.ID ? indicator.ID : '')
    const multipleOptions = useFirestoreGeneral('MultipleQuestionOptions', 'Field', indicator.ID ? indicator.ID : '')
    const matrixQuestionRows = useFirestoreGeneral('MatrixQuestionRows', 'Field', indicator.ID ? indicator.ID : '')

    // Get research id and title
    useEffect(() => {
        research && research.map(doc => {
            setResearchId(doc.ID);
            setTitle(doc.Title);
        });
    }, [research]);

    // Get number of measure moments
    useEffect(() => {
        if (measureMoments) {
            setNumberOfMeasureMoments(measureMoments.length);
        }
    }, [measureMoments]);

    // Get type of moment
    useEffect(() => {
        if (numberOfMeasureMoments === 1) {
            setType(`${indicator.Type}-moment`);
            setMomentId(measureMoments[0].ID);
        } else if (numberOfMeasureMoments > 1) {
            setType(`${indicator.Type}-development`);
        } else {
            setType('output');
        }
    }, [numberOfMeasureMoments]);

    // Function to update indicator visibility
    const updateIndicatorVisibility = async (e) => {
        const type = e.target.dataset.type;
        await db.collection('QuestionnaireFields')
            .doc(docid)
            .update({
                LiveReportHidden: type === 'on' ? false : true
            });
    };

    // Function to select graph type
    const selectGraphType = async (e) => {
        const type = e.target.dataset.type;
        await db.collection('QuestionnaireFields')
            .doc(docid)
            .update({
                GraphType: type
            });

        setModalGraphTypeOpen(false)
    };

    // Onclick function to save the adjusted paragraph graph colors
    const saveColorsParagraph = (e) => {

        const docid = e.target.dataset.docid 
        const color = e.target.value

        db.collection('AnalysisCategories')
        .doc(docid)
        .update({
            Color: color
        })

    }

     // Onclick function to save the adjusted multiple graph colors
     const saveColorsMultiple = (e) => {

        const docid = e.target.dataset.docid 
        const color = e.target.value

        db.collection('MultipleQuestionOptions')
        .doc(docid)
        .update({
            Color: color
        })

    }

    // Onclick function to save the adjusted matrix graph colors
    const saveColorsMatrix = (e) => {

        const docid = e.target.dataset.docid
        const color = e.target.value

        db.collection('MatrixQuestionRows')
        .doc(docid)
        .update({
            Color: color
        })

    }

    // Function to select visibility of graph or text or both for paragraph-moment questions
    const selectTextOrGraphVisibility = async (e) => {

        const selectedOption = e.target.dataset.option 

        await db.collection('QuestionnaireFields')
        .doc(docid)
        .update({
            ParagraphMomentVisibilityStyle: selectedOption 
        })

    }


    // Tooltip content
    const tooltipContent = () => {
        const typeSetting = () => {
            if (measureMoments.length === 1) {
                return 'Momentopname';
            } else if (measureMoments.length > 1) {
                return 'Ontwikkeling';
            } else {
                return 'Outputresultaat';
            }
        };

        return (
            <div className="home-kpi-tooltip-container">
                <p><b>Onderzoek</b></p>
                <p>{title}</p>
                <p><b>Type</b></p>
                <p>{typeSetting()}</p>
                <p><b>Periode</b></p>
                {/* <p>{startDate ? `Start: ${startDate.toDate().toLocaleDateString()}` : 'Alles'}</p>
                <p>{endDate ? `Eind: ${endDate.toDate().toLocaleDateString()}` : 'Alles'}</p>                 */}
            </div>
        );
    };

    return (
        <div className="home-kpi-chart-container">
            <div className="home-kpi-description-container">
                <QuestionMeta field={indicator.ID} />
                <p>{indicator.Title}</p>

                {/* Tooltip */}
                <Tooltip content={tooltipContent()} width={'50px'} top='30px' left='-100px'>
                    <img src={informationIcon} alt="" />
                </Tooltip>
            </div>

            <DataType 
                type={indicator.Type} 
                momentId={momentId} 
                fieldId={indicator.ID} 
                startDate={startDate} 
                endDate={endDate} 
                researchId={researchId} 
                graphType={graphType}
                personaId={personaId}
                paragrapgMomentVisibilityStyle={paragrapgMomentVisibilityStyle}
                matrixQuestionRows={matrixQuestionRows}
            />

            {/* Chart options container */}
            <div id='chart-options-container'
            style={{
                display:
                     currentPage === 'printlivereport' || currentPage === 'livereportopen'
                    ? 'none'
                    : 'flex',
                }}
            >
            {/* Field visible in live report toggle */}
            <div>
            {liveReportHidden ? 
                <Tooltip content={'Niet zichtbaarheid in live rapportage'} width={'50px'} top='30px'>
                    <VisibilityOffOutlinedIcon 
                    data-type={'on'} 
                    onClick={updateIndicatorVisibility}        
                    />
                </Tooltip>
            :
                <Tooltip content={'Zichtbaarheid in live rapportage'} width={'50px'} top='30px'>
                    <VisibilityOutlinedIcon
                    data-type={'off'} 
                    onClick={updateIndicatorVisibility}
                    />
                </Tooltip>
            }
            </div>

            {/* Change graph colors */}
            <div>
                <Tooltip content={'Grafiekkleuren aanpassen'} width={'50px'} top='30px'>
                    <ColorLensOutlinedIcon onClick={() => setModalOpen(true)}/>
                </Tooltip>
            </div>

            {/* Update graphtype */}
            <div>
                <Tooltip content={'Grafiektype aanpassen'} width={'50px'} top='30px'>
                        {
                            graphType === 'bar' ? (
                                <BarChartIcon onClick={() => setModalGraphTypeOpen(true)} />
                            ) : graphType === 'line' ? (
                                <LineChartIcon onClick={() => setModalGraphTypeOpen(true)} />
                            ) : graphType === 'pie' ? (
                                <PieChartIcon onClick={() => setModalGraphTypeOpen(true)} />
                            ) : graphType === undefined ? (
                                <BarChartIcon onClick={() => setModalGraphTypeOpen(true)} />
                            ) : null
                        }
                </Tooltip>
            </div>

            {/* Paragraph-moment visibility style toggle */}
            <div
            id='parargraph-moment-visibility-style-option-container'
            style={{
                display: type === 'paragraph-moment'
                        ? 'flex' 
                        : 'none'
                }}
            >
                <Tooltip content={'Responses zichtbaarheid aanpassen'} width={'50px'} top='30px'>
                    <ChatOutlinedIcon onClick={() => setShowResponsesVisibilityOptions(!showResponsesVisibilityOptions)}/>
                </Tooltip>
                <div className='add-options-container live-report-graph-options-container' style={{display: showResponsesVisibilityOptions ? 'flex' : 'none'}} onMouseLeave={() => setShowResponsesVisibilityOptions(!showResponsesVisibilityOptions)}>
                   <p style={{color: paragrapgMomentVisibilityStyle === 'both' ? 'green' : 'black'}} data-option={'both'} onClick={selectTextOrGraphVisibility}>Reponses + grafiek</p>
                    <p style={{color: paragrapgMomentVisibilityStyle === 'text' ? 'green' : 'black'}} data-option={'text'} onClick={selectTextOrGraphVisibility}>Alleen reponses</p>
                    <p style={{color: paragrapgMomentVisibilityStyle === 'graph' ? 'green' : 'black'}} data-option={'graph'} onClick={selectTextOrGraphVisibility}>Alleen grafiek</p>
                </div>
            </div>
        </div>

            {/* Modal for changing colors */}
            <Modal
        isOpen={modalOpen}
        onRequestClose={() => setModalOpen(false)}
        style={modalStyles}
        contentLabel="Kleur aanpassen van grafiek"
        >
            <div>
                <div className="page-header">
                    <h1>Grafiekkleuren aanpassen</h1>
                </div>
                <div>
                    {/* Change colors for fieldtype paragraph */}
                    {fieldType === 'paragraph' &&
                    <div>
                        {paragraphCategories && paragraphCategories.map(cat => (
                            <div key={cat.ID} className='update-graph-color-container'>
                                <p>{cat.Categorie}</p>
                                <ColorPickerHousestyle item={cat} data-docid={cat.docid} onChange={saveColorsParagraph}/>
                            </div>
                        ))}
                    </div> 
                    }

                    {/* Change colors for fieldtype multiple-one */}
                    {fieldType === 'multiple-one' &&
                    <div>
                        {multipleOptions && multipleOptions.map(option => (
                            <div key={option.ID} className='update-graph-color-container'>
                                <p>{option.Option}</p>
                                <ColorPickerHousestyle item={option} data-docid={option.docid} onChange={saveColorsMultiple}/>
                            </div>
                        ))}
                    </div> 
                    }

                    {/* Change colors for fieldtype multiple-multiple */}
                    {fieldType === 'multiple-multiple' &&
                    <div>
                        {multipleOptions && multipleOptions.map(option => (
                            <div key={option.ID} className='update-graph-color-container'>
                                <p>{option.Option}</p>
                                <ColorPickerHousestyle item={option} data-docid={option.docid} onChange={saveColorsMultiple}/>
                            </div>
                        ))}
                    </div> 
                    }

                    {/* Change colors for fieldtype multiple-one */}
                    {fieldType === 'matrix-one' &&
                    <div>
                        {matrixQuestionRows && matrixQuestionRows.map(row => (
                            <div key={row.ID} className='update-graph-color-container'>
                                <p>{row.Title}</p>
                                <ColorPickerHousestyle item={row} data-docid={row.docid} onChange={saveColorsMatrix}/>
                            </div>
                        ))}
                    </div> 
                    }

                    {/* Change colors for fieldtype matrix-multiple */}
                    {fieldType === 'matrix-multiple' &&
                    <div>
                        {matrixQuestionRows && matrixQuestionRows.map(row => (
                            <div key={row.ID} className='update-graph-color-container'>
                                <p>{row.Title}</p>
                                <ColorPickerHousestyle item={row} data-docid={row.docid} onChange={saveColorsMatrix}/>
                            </div>
                        ))}
                    </div> 
                    }
                   
                </div> 
                <div id='modal-button-container'>
                    <button id='modal-cancel-button'onClick={() => setModalOpen(false)}>Annuleren</button>
                    <button id='modal-save-button'  onClick={() => setModalOpen(false)}>Opslaan</button>
                </div>
            </div>
        </Modal>
        <Modal
        isOpen={modalGraphTypeOpen}
        onRequestClose={() => setModalGraphTypeOpen(false)}
        style={modalStyles}
        contentLabel="Grafiek type aanpassen"
        >
            <div>
                <div className="page-header">
                    <h1>Grafiektype aanpassen</h1>
                </div>
                <div>
                    <div className='update-graph-type-container'>
                        <input type="radio" defaultChecked={graphType === 'bar'} data-type={'bar'} name='barchart' onClick={selectGraphType}/>
                        <BarChartIcon />
                        <p>Staafdiagram</p>
                    </div>
                    <div className='update-graph-type-container'>
                        <input type="radio" defaultChecked={graphType === 'line'} name='barchart' data-type={'line'} onClick={selectGraphType}/>
                        <LineChartIcon />
                        <p>Lijngrafiek</p>
                    </div>
                    <div className='update-graph-type-container'>
                        <input type="radio" defaultChecked={graphType === 'pie'} name='barchart' data-type={'pie'} onClick={selectGraphType}/>
                        <PieChartIcon />
                        <p>Taartdiagram</p>
                    </div>
                   
                </div> 
                <div id='modal-button-container'>
                    <button id='modal-cancel-button'onClick={() => setModalGraphTypeOpen(false)}>Annuleren</button>
                </div>
            </div>
        </Modal>
        </div>
    );
};

export default IndicatorData;
