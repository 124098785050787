import { useState } from "react";
import SyncOutlinedIcon from "@mui/icons-material/SyncOutlined";
import { useFirestoreNoCompagnyGeneralOrderBy } from "../../firebase/useFirestore";
import useSettings from "../../hooks/Settings";
import { useHistory } from "react-router-dom";
import { client } from "../../hooks/Client";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import CompagnyMeta from "../../components/portfolio/CompagnyMeta";
import EffectMeta from "../../components/effects/EffectMeta";
import OutputMeta from "../../components/outputs/OutputMeta";

const Synchronisations = () => {
  // State
  const [outputTitles, setOutputTitles] = useState({});
  const [effectTitles, setEffectTitles] = useState({});

  // Hooks
  const secundairyColor = useSettings().SecundairyColor;
  const history = useHistory();

  // Firestore
  const syncs = useFirestoreNoCompagnyGeneralOrderBy('Synchronisations', 'Project', client, 'Timestamp', 'desc');

  const status = (statusCode) => {
    switch (statusCode) {
      case "requested":
        return { text: "Aangevraagd", color: "#FFA500" };
      case "accepted":
        return { text: "Geaccepteerd", color: "#008000" };
      case "declined":
        return { text: "Geweigerd", color: "#FF0000" };
      case "deleted":
        return { text: "Verwijderd", color: "#000000" };
      default:
        return { text: "Onbekende status", color: "#000000" };
    }
  };

  const syncStatus = (statusCode) => {
    switch (statusCode) {
      case "requested":
        return { text: "Inactief", color: "#FFA500" };
      case "accepted":
        return { text: "Actief", color: "#008000" };
      case "declined":
        return { text: "Inactief", color: "#FF0000" };
      case "deleted":
        return { text: "Inactief", color: "#FF0000" };
      default:
        return { text: "Onbekende status", color: "#000000" };
    }
  };

  const type = (type) => {
    switch (type) {
      case "Output":
        return "Output";
      case "Effect":
        return "Effect";
      default:
        return "Onbekend";
    }
  };

  return (
    <div className="main">
      <div className="main-container">
        <div className="page-header">
          <SyncOutlinedIcon />
          <h1>Synchronisations</h1>
        </div>
        <div className="dashboard-container">
          <table>
            <thead>
              <tr>
                <th style={{ backgroundColor: secundairyColor }}>ORGANIATIE</th>
                <th style={{ backgroundColor: secundairyColor }}>TYPE</th>
                <th style={{ backgroundColor: secundairyColor }}>NAAM</th>
                <th style={{ backgroundColor: secundairyColor }}>STATUS</th>
                <th style={{ backgroundColor: secundairyColor }}>AANPASSEN</th>
              </tr>
            </thead>
            <tbody>
              {syncs &&
                syncs.map((sync) => (
                  <tr key={sync.ID}>
                    <td>
                      <CompagnyMeta id={sync.Parent} />
                    </td>
                    <td>{sync.Type}</td>
                    <td>
                      {sync.Type === "Output" ? (
                        <p>{<OutputMeta id={sync.Output}/>}</p>
                      ) : sync.Type === "Effect" ? (
                        <p>{<EffectMeta item={sync.Effect}/>}</p>
                      ) : null}
                    </td>
                    <td>
                      <p style={{ color: status(sync.Status).color }}>
                        {status(sync.Status).text}
                      </p>
                    </td>
                    <td>
                      <EditOutlinedIcon
                        className="table-delete-icon"
                        onClick={() =>
                          history.push(
                            `/${client}/synchronisationbuilder/${sync.ID}`
                          )
                        }
                        alt=""
                      />
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default Synchronisations;

